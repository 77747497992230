.footer {
    font-size: $footer-font-size;
    background: $white;
    .footer-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0.5rem calc(var(--bs-gutter-x, .75rem) * 3);
        @include media-breakpoint-down(md) {
            justify-content: center;
        }
    }
}