.page-item {
    &.active{
        .page-link{
            color:var(--#{$variable-prefix}white);
            background-color: var(--#{$variable-prefix}primary);
            border-color: var(--#{$variable-prefix}primary);
        }
    }
    .page-link{
        border-color: var(--bs-primary-tint-80);
        color: var(--bs-primary);
    }
}