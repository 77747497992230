.gradient-bottom-left {
    height: 100vh;
    //background: radial-gradient(20% 60% at 50% 50%, #3B8AFF 0%, #0048b3 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    overflow: hidden;

    .c {
        border-radius: 50%;
        padding: 5rem;
       background: radial-gradient(60% 60% at 50% 50%, transparent 67%, #00000078 100%);
        // box-shadow: inset 0 0 100px -20px #041e46;
        // box-shadow: inset 24px 32px 184px 24px rgba(6, 8, 89, 0.75);
        .xs-circle{
            padding: 250px;
        }
    }

    .xl-circle {
        transform: translate(-21px, 250px)
    }
    .lg-circle {
        transform: translate(-139px, 60px);
       
    }
    .md-circle {
        transform: translate(2px, -6px);
    }
    .sm-circle {
        transform: translate(-56px, -19px);
    }
    .xs-circle {
        transform:translate(-4px, -56px);
    }
}