
.credit-card-widget{
    .card-header {
        &::before {
            left: auto;
            right: -3.125rem;
        }
        &::after {
            left: -3.125rem;
            right: auto;
        }
    }
    .primary-gradient-card {
		.master-card-content {
			.master-card-2 {
                margin-left: unset;
                margin-right: -2rem;
			}
		}
	}
}