.card {
    box-shadow: $card-box-shadow;
    margin-bottom: 0;
    .card-header {
		margin-bottom: 0;
		border: 0;
		padding-bottom: 0;
		.header-title {
			.card-title {
				margin-bottom: 0;
			}
		}
	}
}

.card-header {
    .card-title {
        margin-bottom: 0;
    }
}

.card-body {
	padding: 0.5rem 0.5rem;
}
